import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { Container } from "../components/Container";
import codeImg from "../assets/code.jpg";

const content = `
Today I work with designing and maintaining full-stack applications. I work on mobile and web apps, with APIs, databases, and servers. How did I get where I am today?

This section is a summary of my past experiences developing software solutions. A case can be made that languages, frameworks, and libraries aren't significant in the grand scheme of things – it is the final applications and solutions that the users interact with that are important. This is true. Any competent developer can pick up a new programming language and learn new frameworks. However, from the developers perspective, it may still meaningful to speak about these technologies.

My professional journey in programming started with creating iOS applications. I started consuming existing APIs and web services early on, but I had only a high-level understanding of how they worked.

After a year at university, I had gained experience with programming in Python and Java which enabled me to write Android applications as well.

My introduction to the web started with an exploration of the different Javascript frameworks and frontend tooling. I remember reading this article from 2016: <a href="https://hackernoon.com/how-it-feels-to-learn-javascript-in-2016-d3a717dd577f" target="_blank" rel=”noreferrer noopener”>https://hackernoon.com/how-it-feels-to-learn-javascript-in-2016-d3a717dd577f</a> and was (stubbornly) determined to understand every word. 

 I chose to learn React and I have stuck to using it for most of my projects. 

React Native was probably what made React stick out from the competition. Cross-platform or universal development was something I had considered during my time developing native iOS apps, but the tools were just not mature at the time. Today, React Native offers a fairly mature cross-platform solution, resulting in an enormous improvement in developer productivity. The recent developments with React Native Web even allow websites and desktop apps to share code with mobile applications.

Since my early days, I have progressively been more involved with designing APIs and writing business logic. I started working with PHP applications but have since transitioned to Node.js, since I found using Typescript on both the frontend and backend resulted in significant productivity gains. As I was already using Facebook's open-source projects, I decided to investigate using GraphQL as the API-layer for my services, which I found was delightful to work with.

`;
const SecondPage = () => (
  <Layout heroSection={false}>
    <SEO title="Developer story" />
    <Container>
      <article
        style={{
          padding: "40px 0 20px 0",
        }}
      >
        <h1>Developer story</h1>
        <img
          src={codeImg}
          alt="Code"
          style={{ maxHeight: 400, width: "100%", objectFit: "cover" }}
        />

        {content.split("\n\n").map((item, i) => (
          <p
            key={i}
            dangerouslySetInnerHTML={{
              __html: item,
            }}
          />
        ))}
      </article>
    </Container>
  </Layout>
);

export default SecondPage;
